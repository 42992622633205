// 资质证书
<template>
  <div class="honour-certificate">
    <InfoImg :span='24' :preview='true' />
  </div>
</template>

<script>
import InfoImg from '@/components/InfoImg'
export default {
  components: {
    InfoImg
  },
};
</script>

<style scoped lang="less">
/deep/ .infoImg-item {
  text-align: center;
  padding: 36px 0;
  background-color: #fff;
  border-radius: 23px;
  background-repeat: no-repeat;
  background-size: cover;
  &:first-child {
    background-image: url("../../assets/images/zzry-bn1.png");
  }
  &:last-child {
    background-image: url("../../assets/images/zzry-bn2.png");
  }
}
</style>
